import './TopBarStyle.css';

export default function TopBar({changePage}){
	return(
		<div className="TopBar">
			<div>
				<p className="Title" onClick={()=>changePage("home")}>Sasha Sabherwal, PhD</p>
			</div>
		
			<div className="NavBar">
				<p onClick={()=>changePage("home")}> Home</p>
				<p onClick={()=>changePage("publications")}> Research</p>
			</div>
			
		
		</div>
	)
}