import {useState} from 'react';
import TopBar from './Components/TopBar.jsx';
import Home from './Pages/Home.jsx';
import Publications from './Pages/Publications.jsx';

import './App.css';


function App() {
	const [page, setPage] = useState("home");
	
	let currentPage;
	function changePage(page){
		setPage(page);
	}
	
	switch(page){
		case "home":
			currentPage = (<Home />);
			break;
		case "publications":
			currentPage = (<Publications/>);
			break;
		default:
			currentPage = (<Home />);
			break;
	}
	
	let currentYear = new Date().getFullYear();
	
	
  return (
    <div className="App">
  
       	<TopBar changePage={changePage}/>
		 <div className="MainBody">
		  	{currentPage}
		  </div>
		  
		  <div className="Copyright">
		  	<p>©{currentYear} Sasha Sabherwal</p>
		  </div>
      
    </div>
  );
}

export default App;
