import './HomeStyle.css';
import profilePic from '../pics/DSC_0079.JPG';
import SplashImage from '../Components/SplashImage';

export default function Home(){
	return(
		<div>
			<div className="TopSection">
				<SplashImage imageURL={profilePic}  />	
				<FrontPageText />
				<ContactInfo />
			</div>
		
		</div>
	)
}


function FrontPageText(){
	return(
		<div className="FrontPageText DescriptiveText">

				<p>Sasha Sabherwal (she/her) is an interdisciplinary scholar of the South Asian diaspora with research interests in critical ethnic studies, 
					the racialization of religion, transnational gender and sexuality studies, and the intersections of caste and race. 
					She is an Assistant Professor of Cultural Anthropology and Asian Studies at Northeastern University. 
					She holds a PhD from Yale University in the Department of American Studies with a certificate in Women’s, Gender, and Sexuality Studies, and a BA <i>magna cum laude</i> from the University of California, 
					Irvine in Gender and Sexuality Studies, International Studies, and Political Science. 
					She was previously a postdoctoral fellow at the Center for the Humanities at Tufts University, 
					and a Chancellor’s Postdoctoral Fellow in the Department of Asian American Studies at the University of Illinois Urbana Champaign.</p>

					<p>Dr. Sabherwal’s research examines caste and gender hierarchies in the Sikh diaspora of the Pacific Northwest.
						 Her book manuscript, <i>Circuits of Faith: Caste, Gender, and Racialized Religion in the Sikh Diaspora of the Transnational Pacific Northwest</i>, 
						 is an ethnographic exploration of how caste is (re)produced across the U.S. and Canada, and how it becomes a heterogeneous and flexible category. 
						 The project demonstrates that as caste travels, it is remade, changing across different diasporic spaces, 
						 and oscillating between traditional caste hierarchies from the homeland and contemporary diasporic articulations. 
						 Her research offers an analysis of race, gender, and religion within a transnational 
					community in the western borderland region of the Pacific Northwest to highlight the diversity of caste—not just within Hindu diasporic communities—but its ubiquity across South Asian religions.</p>


		</div>	
	)
}

function ContactInfo(){
	
	
	let me = "s.sabherwal";
	let place = "northeastern.edu";
	

	
	return(
		<div className="ContactInfo">
			<p>Contact me at {<a href={`mailto:${me}@${place}`}>{`${me}@${place}`}</a>}</p>

		</div>
	)
}