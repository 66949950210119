export default function SplashImage({imageURL, caption=""}){
	let captionTag = null;

	if(caption.length>0){
		captionTag = (<p className="Caption">{caption}</p>);
	}

	return(
		<div className="SplashImage">
			<img src={imageURL} />
			{captionTag}
		</div>
	)
	
};