import './PublicationsStyle.css';
import SplashImage from '../Components/SplashImage.jsx';
import splashPic from '../pics/74a8d364f42e748b.jpg';

export default function Publications(){
	let splashCaption = ["Caste in Canada Roundtable, University of British Columbia, 2019", <br/>, "From left to right: Inderdeep Dhaliwal, Anita Lal, Satwinder Bains, Sasha Sabherwal, Jai Birdi, and Anne Murphy"];

	return(
		<div>
			<SplashImage imageURL={splashPic} caption={splashCaption}/>
			<PubList />
			
		
		</div>);
}





function PubList(){
	return (<div className="PubList">
				<div>
					<h2 className="PubSectionHeader">Peer Reviewed Journal Articles</h2>
					<p className="PubListing">“<a href="https://doi.org/10.1215/01642472-9771077">The Security State and Securitizing Patriarchies in Postcolonial India</a>.” <em>Social Text</em> (2022)</p>
					<p className="PubListing">“<a href="https://www.tandfonline.com/doi/abs/10.1080/17448727.2019.1703079">Slow Violence in Post-1984 Punjab: Remembering, Forgetting and Refusals</a>." <em>Sikh Formations</em> (2020)</p>
				</div>
				<div>
					<h2 className="PubSectionHeader">Book Chapters</h2>
					<p className="PubListing">Sabherwal, Sasha and Anita Lal, “<a rel="noreferrer noopener" href="https://saclp.southasiancanadianheritage.ca/social-history-book/" target="_blank">Dalit Diversity: Contours of Caste in the Lower Mainland</a><a href="https://saclp.southasiancanadianheritage.ca/social-history-book/">, British Columbia</a>,” in <em>Social History of South Asian Canadians in British Columbia</em>. University of Fraser Valley Press (2022)</p>
				</div>
				<div>
					<h2 className="PubSectionHeader">Book Reviews</h2>
					<p className="PubListing">“Relation and Resistance: Racialized Women, Religion, and Diaspora (review),” <em>University of Toronto Quarterly</em>, forthcoming (2023)</p>
					<p className="PubListing">“Gendered Citizenship: Understanding Gendered Violence in Democratic India by Natasha Behl (review),” <em>Sikh Formations</em>, forthcoming (2023)</p>
					<p className="PubListing">“<a href="https://sikhresearchjournal.org/book-colloquium/">Faith, Gender, and Activism in the Punjab Conflict: The Wheat Fields Still Whisper by Mallika Kaur (review),"</a> (2020)</p>
					<p className="PubListing">“<a href="https://muse.jhu.edu/article/686177/pdf">Everyday Conversions: Islam, Domestic Work, and South Asian Migrant Women in Kuwait by Attiya Ahmad (review),"</a> (2017)</p>
				</div>
				<div>
					<h2 className="PubSectionHeader">Other Publications</h2>
					<p className="PubListing">“<a href="https://southasia.macmillan.yale.edu/news/notes-field-vancouver-british-columbia">Notes from the Field in Vancouver, British Columbia</a>” (2019)</p>
				</div>
			
			
		</div>);
	
}

